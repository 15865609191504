import React, { useState, useEffect, useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";
import axios from "axios";

import Widget from "../../components/Widget/Widget";
import GoBack from "../../components/GoBack";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useHistory } from "react-router-dom";
import {
    Button,
    FormGroup,
    Label,
    FormFeedback,
    Input,
    Col,
    Row,
    InputGroup,
    InputGroupText,
} from "reactstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import AsyncSelectField from "../../components/async-select";
import { useParams } from "react-router-dom";
import moment from "moment";
import Dropzone from "react-dropzone";
import config from "../../config";
import { Link } from "react-router-dom";
import useFetch from "../../components/hooks/use-fetch";
import FormSubmit from "../forms/form";
import PrintButton from "./components/print-pdf";
import EsignForm from "./components/esign-form";

const validationSchema = Yup.object().shape({
    name: Yup.string().required("Name is required").nullable(),
    description: Yup.string().required("Description is required").nullable(),
    dateCampaignStartString: Yup.date().required("Start date is required").nullable(),
    dateCampaignEndString: Yup.date()
        .required("End date is required")
        .min(
            Yup.ref("dateCampaignStartString"),
            "End date must be after start date"
        ).nullable(),
    totalSumExcTax: Yup.number()
        .typeError("Total Sum must be a number")
        .required("Total Sum is required")
        .min(0, "Total Sum must be greater than or equal to 0").nullable(),
    rightOfCreatedPost: Yup.boolean()
        .required("Please select an option")
        .nullable(),
    typeOfServiceId: Yup.number().required("Please select a type of service").nullable(),
    statusId: Yup.number().required("Please select a status").nullable(),
});

const QuoteForm = () => {

    const { t } = useTranslation();
    const { quoteId } = useParams();
    const history = useHistory();

    const { currentUser } = useSelector((state) => state.auth);

    const [isSubmitting, setIsSubmitting] = useState(false);


    const [typeOfServiceOptions, setTypeOfServiceOptions] = useState([]);
    const [existingFiles, setExistingFiles] = useState([]);
    const [deletedFiles, setDeletedFiles] = useState([]);

    const [isChangeCompany, setIsChangeCompany] = useState(false);

    const [esignLoading, setEsignLoading] = useState(false);

    const { data: quote, refetch: reFetchQuote } = useFetch(`/quotes/${quoteId}`);

    useEffect(() => {
        if (quote) {
            formik.resetForm({
                values: {
                    ...quote,
                    dateCampaignStartString: quote?.dateCampaignStart ? new Date(quote.dateCampaignStart) : null,
                    dateCampaignEndString: quote?.dateCampaignEnd ? new Date(quote.dateCampaignEnd) : null,
                    files: [],
                }
            });
            setExistingFiles(quote.files);
        }
    }, [quote]);



    useEffect(() => {
        if (quoteId) {
            reFetchQuote();
        }
    }, [quoteId]);

    useEffect(() => {
        const fetchTypeOfServiceOptions = async () => {
            try {
                const response = await axios.get("/options-list/quote_type_of_service");
                setTypeOfServiceOptions(response.data);
            } catch (error) {
                console.error("Error fetching typeOfService options:", error);
            }
        };

        fetchTypeOfServiceOptions();
    }, []);

    const convertToFormData = (data) => {
        const formData = new FormData();

        Object.keys(data).forEach((key) => {
            if (key === "files") {
                data[key].forEach((file) => {
                    formData.append("files[]", file);
                });
            } else if (key === "connectedCompanyIds") {
                data[key].forEach((id) => {
                    formData.append("connectedCompanyIds[]", id);
                });
            } else {
                if (data[key] === null || data[key] === undefined) return;

                formData.append(key, data[key]);
            }
        });

        return formData;
    };

    const formSubmitRef = useRef(null);

    const esignRef = useRef(null);

    const formik = useFormik({
        initialValues: {
            name: "",
            description: "",
            dateCampaignStartString: null,
            dateCampaignEndString: null,
            totalSumExcTax: null,
            rightOfCreatedPost: false,
            statusId: null,
            typeOfServiceId: null,
            files: [],
            totalNumberPosts: null,
            comment: "",
            nrOfMonth: null,
        },
        validationSchema,
        onSubmit: async (values) => {

            if (values.statusId === config.quoteStatuses.approve && !submissions?.length) {

                toast.error(t("You need to fill in the form before changing the status to approve"));
                return;

            }

            const formattedValues = {
                ...values,
                dateCampaignEndString: moment(values.dateCampaignEndString).format("YYYY-MM-DD"),
                dateCampaignStartString: moment(values.dateCampaignStartString).format("YYYY-MM-DD"),
                createdUserId: currentUser.id,
                deletedFiles,
                files: values.files.map((file) => file.file || file),
            };

            const formData = convertToFormData(formattedValues);

            try {
                setIsSubmitting(true);
                const response = await axios.post(`/quotes/${quoteId || ""}`, formData);
                if (quoteId) {
                    toast.success(t("Quote successfully updated!"));
                } else {
                    toast.success(t("Quote successfully created!"));
                }

                setIsChangeCompany(false);
                reFetchQuote();

            } catch (error) {
                console.error("Error submitting form:", error);
                toast.error(t("Failed to submit quote. Please try again."));
            } finally {
                setIsSubmitting(false);
            }

            if (formSubmitRef.current) {
                formSubmitRef.current.submit();
            }
        },
    });

    const handleDrop = (acceptedFiles) => {
        formik.setFieldValue('files', [...formik.values.files, ...acceptedFiles]);

    };

    const handleRemoveFile = (index, isExisting) => {
        if (isExisting) {
            const fileToDelete = existingFiles[index];
            setDeletedFiles([...deletedFiles, fileToDelete.id]);
            setExistingFiles(existingFiles.filter((_, i) => i !== index));
        } else {
            formik.setFieldValue(
                "files",
                formik.values.files.filter((_, i) => i !== index)
            );
        }
    };

    const role = useMemo(() => currentUser?.role, [currentUser]);


    const { data: submissions, loading: dataLoading } = useFetch(`/submit-forms?limit=1&quoteId=${quoteId}`);

    const isDisabled = useMemo(() => {
        if (quote && (quote?.statusId !== config.quoteStatuses.changes && quote?.statusId !== config.quoteStatuses.sendRequest && quote?.statusId !== config.quoteStatuses.new)) {
            return true;
        }
        return false;
    });

    const completeQuote = async () => {
        setIsSubmitting(true);
        try {
            await axios.post(`/quotes/${quoteId}`, {
                statusId: config.quoteStatuses.complete,
            });

            toast.success(t("Order successfully completed!"));
            reFetchQuote();
        } catch (error) {
            console.error("Error completing order:", error);
            toast.error(t("Failed to complete order. Please try again."));
        } finally {
            setIsSubmitting(false);
        }
    }


    useEffect(() => {
        const unblock = history.block((location, action) => {
            if (formik.dirty) {
                return t("You have unsaved changes. Are you sure you want to leave?");
            }
        });

        return () => {
            unblock();
        };
    }, [formik.dirty, history]);


    return (
        <div>
            <GoBack />
            <Widget title={
                <Row>
                    <Col md={2}><h3>{t(!quoteId ? "Create quote" : "Edit")}</h3>
                    </Col>
                    <Col md={2} className="text-left">
                        {!dataLoading && (!submissions || submissions?.length === 0) && currentUser.role === "ROLE_ADMIN" &&
                            <Button type="button" color="danger" onClick={() => {
                                if (currentUser.role === "ROLE_ADMIN") {
                                    history.push(`/admin/${quoteId}/form`);
                                } else {
                                    history.push(`/app/${quoteId}/form`);
                                }

                            }}>{t("More information")}</Button>}
                    </Col>


                </Row>
            }>
                {/* <Prompt when={formik.dirty} message={t("You have unsaved changes. Are you sure you want to leave?")} /> */}
                <form onSubmit={formik.handleSubmit}>
                    <Row>
                        <Col md={12}>
                            <FormGroup>
                                <Label className="fw-semi-bold" for="name">{t("Name")}</Label>
                                <Input
                                    id="name"
                                    name="name"
                                    type="text"
                                    value={formik.values.name}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    invalid={formik.touched.name && !!formik.errors.name}
                                    readOnly={isDisabled}
                                />
                                {formik.touched.name && formik.errors.name && (
                                    <FormFeedback>{t(formik.errors.name)}</FormFeedback>
                                )}
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12}>
                            <FormGroup>
                                <Label className="fw-semi-bold">{t("Type of Service")}</Label>
                                <div>
                                    {typeOfServiceOptions.map((option) => (
                                        <FormGroup check inline key={option.id}>
                                            <Label check>
                                                <Input
                                                    type="radio"
                                                    name="typeOfServiceId"
                                                    value={option.id}
                                                    disabled={isDisabled}
                                                    checked={
                                                        formik.values.typeOfServiceId ===
                                                        option.id
                                                    }
                                                    onChange={() =>
                                                        formik.setFieldValue(
                                                            "typeOfServiceId",
                                                            option.id
                                                        )
                                                    }
                                                />
                                                {t(option.name)}
                                            </Label>
                                        </FormGroup>
                                    ))}
                                </div>
                                {formik.touched.typeOfServiceId && formik.errors.typeOfServiceId && (
                                    <div className="text-danger mt-1">
                                        {t(formik.errors.typeOfServiceId)}
                                    </div>
                                )}



                            </FormGroup>
                        </Col>
                    </Row>

                    <Row>
                        <Col md={12}>
                            <FormGroup>
                                <Label className="fw-semi-bold mb-0" for="description">{t("Description")}</Label>
                                <div className="mb-2"><small className="form-text text-muted">{t("quote-help-text-description")}</small></div>
                                <Input
                                    id="description"
                                    name="description"
                                    type="textarea"
                                    value={formik.values.description}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    readOnly={isDisabled}
                                    invalid={
                                        formik.touched.description &&
                                        !!formik.errors.description
                                    }
                                />
                                {formik.touched.description &&
                                    formik.errors.description && (
                                        <FormFeedback>
                                            {t(formik.errors.description)}
                                        </FormFeedback>
                                    )}
                            </FormGroup>
                        </Col>

                    </Row>

                    <Widget className="ps-0 pe-0">
                        {currentUser.role === "ROLE_ADMIN" && !isDisabled && <Button type="button" className="position-absolute" style={{
                            right: 10,
                            top: 10
                        }} color="primary" size="xs" onClick={() => setIsChangeCompany(!isChangeCompany)}>{t("Change company")}</Button>}
                        <Row>
                            <Col md={6}>
                                <FormGroup>
                                    <Label className="fw-semi-bold" for="companyId">{t("Seller company")}</Label>
                                    <div>
                                        {currentUser.role === "ROLE_ADMIN" ?
                                            isChangeCompany ?
                                                <AsyncSelectField
                                                    url="/companies?typeId=2"
                                                    defaultValue={formik.values.sellerCompanyId}
                                                    handleSelectChange={(selectedOption) => {
                                                        formik.setFieldValue("sellerCompanyId", selectedOption.id);
                                                    }}
                                                />
                                                :
                                                (<Link to={`/admin/companies/${formik.values?.sellerCompanyId}/edit`}>
                                                    <i className="fa fa-edit me-2" />
                                                    {formik.values?.sellerCompanyName}</Link>)

                                            : <Input type="text" value={formik.values?.sellerCompanyName} readOnly />
                                        }
                                    </div>
                                </FormGroup>
                            </Col>
                            <Col md={6}>
                                <FormGroup>
                                    <Label className="fw-semi-bold" for="">{t("Buyer company")}</Label>
                                    <div>
                                        {currentUser.role === "ROLE_ADMIN" ?
                                            isChangeCompany ?
                                                <AsyncSelectField
                                                    url={"/companies?typeId=1"}
                                                    defaultValue={formik.values.buyerCompanyId}
                                                    handleSelectChange={(selectedOption) => {
                                                        formik.setFieldValue("buyerCompanyId", selectedOption.id);
                                                    }}
                                                />
                                                :
                                                <Link to={`/admin/companies/${formik.values?.buyerCompanyId}/edit`}>
                                                    <i className="fa fa-edit me-2" />
                                                    {formik.values?.buyerCompanyName}</Link> :
                                            <Input type="text" value={formik.values?.buyerCompanyName} readOnly />
                                        }

                                    </div>
                                </FormGroup>
                            </Col>
                        </Row>
                    </Widget>

                    <Row>
                        <Col md={6}>
                            <FormGroup>
                                <Label className="fw-semi-bold mb-0" for="dateCampaignStartString">
                                    {t("Start date")}
                                </Label>
                                <div className="mb-2"><small className="form-text text-muted">{t("quote-help-text-startdate")}</small></div>

                                <div>
                                    <DatePicker
                                        wrapperClassName="w-100"
                                        style={{ width: "100%" }} // Full-width style
                                        autoComplete="off"
                                        id="dateCampaignStartString"
                                        disabled={isDisabled}
                                        selected={formik.values?.dateCampaignStartString ? new Date(formik.values.dateCampaignStartString) : null}
                                        onChange={(date) =>
                                            formik.setFieldValue(
                                                "dateCampaignStartString",
                                                date
                                            )
                                        }
                                        onBlur={() =>
                                            formik.setFieldTouched(
                                                "dateCampaignStartString"
                                            )
                                        }
                                        className={`form-control ${formik.touched.dateCampaignStartString &&
                                            formik.errors.dateCampaignStartString
                                            ? "is-invalid"
                                            : ""
                                            }`}
                                        dateFormat="yyyy-MM-dd"
                                    />
                                    {formik.touched.dateCampaignStartString &&
                                        formik.errors.dateCampaignStartString && (
                                            <div className="text-danger mt-1">
                                                {t(formik.errors.dateCampaignStartString)}
                                            </div>
                                        )}
                                </div>
                            </FormGroup>
                        </Col>
                        <Col md={6}>
                            <FormGroup>
                                <Label className="fw-semi-bold mb-0" for="dateCampaignEndString">
                                    {t("End date")}
                                </Label>
                                <div className="mb-2"><small className="form-text text-muted">{t("quote-help-text-enddate")}</small></div>

                                <div>
                                    <DatePicker
                                        wrapperClassName="w-100"
                                        style={{ width: "100%" }} // Full-width style
                                        id="dateCampaignEndString"
                                        selected={formik.values?.dateCampaignEndString ? new Date(formik.values.dateCampaignEndString) : null}
                                        format="yyyy-MM-dd"
                                        disabled={isDisabled}
                                        onChange={(date) =>
                                            formik.setFieldValue(
                                                "dateCampaignEndString",
                                                date
                                            )
                                        }
                                        autoComplete="off"
                                        onBlur={() =>
                                            formik.setFieldTouched("dateCampaignEndString")
                                        }
                                        className={`form-control ${formik.touched.dateCampaignEndString &&
                                            formik.errors.dateCampaignEndString
                                            ? "is-invalid"
                                            : ""
                                            }`}
                                        dateFormat="yyyy-MM-dd"
                                    />
                                    {formik.touched.dateCampaignEndString &&
                                        formik.errors.dateCampaignEndString && (
                                            <div className="text-danger mt-1">
                                                {t(formik.errors.dateCampaignEndString)}
                                            </div>
                                        )}
                                </div>
                            </FormGroup>
                        </Col>
                    </Row>

                    <Row>
                        <Col md={6}>
                            <FormGroup>
                                <Label className="fw-semi-bold mb-0" for="rightOfCreatedPost">
                                    {t("Right of Created Post")}
                                </Label>
                                <div className="mb-2"><small className="form-text text-muted">{t("quote-help-text-right-of-created-post")}</small></div>
                                <div>
                                    <FormGroup check>
                                        <Label check>
                                            <Input
                                                type="radio"
                                                name="rightOfCreatedPost"
                                                value={true}
                                                checked={formik.values.rightOfCreatedPost === true}
                                                disabled={isDisabled}
                                                onChange={() =>
                                                    formik.setFieldValue(
                                                        "rightOfCreatedPost",
                                                        true
                                                    )
                                                }
                                            />
                                            {t("True_quote", { context: "quote" })}
                                        </Label>
                                    </FormGroup>
                                    <FormGroup check>
                                        <Label check>
                                            <Input
                                                type="radio"
                                                name="rightOfCreatedPost"
                                                value={false}
                                                disabled={isDisabled}
                                                checked={formik.values.rightOfCreatedPost === false}
                                                onChange={() => {
                                                    formik.setFieldValue(
                                                        "rightOfCreatedPost",
                                                        false
                                                    )

                                                    formik.setFieldValue(
                                                        "nrOfMonth",
                                                        0
                                                    )

                                                }

                                                }
                                            />
                                            {t("False_quote", { context: "quote" })}
                                        </Label>
                                    </FormGroup>
                                </div>
                                {formik.touched.rightOfCreatedPost &&
                                    formik.errors.rightOfCreatedPost && (
                                        <div className="text-danger mt-1">
                                            {t(formik.errors.rightOfCreatedPost)}
                                        </div>
                                    )}
                            </FormGroup>
                        </Col>

                        {formik.values.rightOfCreatedPost && (
                            <Col md={6}>
                                <FormGroup>
                                    <Label className="fw-semi-bold" for="nrOfMonth">
                                        {t("Number of month")}
                                    </Label>
                                    <Input
                                        id="nrOfMonth"
                                        name="nrOfMonth"
                                        type="number"
                                        readOnly={isDisabled}
                                        value={formik.values.nrOfMonth}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                    />

                                </FormGroup>
                            </Col>
                        )}


                        <Col md={6}>
                            <FormGroup>
                                <Label className="fw-semi-bold" for="totalNumberPosts">
                                    {t("Total number posts")}
                                </Label>
                                <Input
                                    id="totalNumberPosts"
                                    name="totalNumberPosts"
                                    type="number"
                                    value={formik.values.totalNumberPosts}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    readOnly={isDisabled}
                                    invalid={
                                        formik.touched.totalNumberPosts &&
                                        !!formik.errors.totalNumberPosts
                                    }
                                />
                                {formik.touched.totalNumberPosts &&
                                    formik.errors.totalNumberPosts && (
                                        <FormFeedback>
                                            {t(formik.errors.totalNumberPosts)}
                                        </FormFeedback>
                                    )}
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>

                        <Col md={6}>
                            <FormGroup>
                                <Label className="fw-semi-bold" for="totalSumExcTax">
                                    {t("Total Sum Excluding Tax")}
                                </Label>
                                <InputGroup>
                                    <Input
                                        id="totalSumExcTax"
                                        name="totalSumExcTax"
                                        type="text"
                                        readOnly={isDisabled}
                                        value={formik.values.totalSumExcTax
                                            ? formik.values.totalSumExcTax.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")
                                            : ""}
                                        onChange={(e) => {
                                            const rawValue = e.target.value.replace(/ /g, "");
                                            if (!isNaN(rawValue)) {
                                                formik.setFieldValue("totalSumExcTax", rawValue);
                                            }
                                        }}
                                        onBlur={(e) => {
                                            const rawValue = e.target.value.replace(/ /g, "");
                                            formik.setFieldTouched("totalSumExcTax", true);
                                            formik.setFieldValue("totalSumExcTax", rawValue);
                                        }}
                                        invalid={
                                            formik.touched.totalSumExcTax && !!formik.errors.totalSumExcTax
                                        }
                                    />
                                    <InputGroupText>kr</InputGroupText>
                                    {formik.touched.totalSumExcTax && formik.errors.totalSumExcTax && (
                                        <FormFeedback>
                                            {t(formik.errors.totalSumExcTax)}
                                        </FormFeedback>
                                    )}
                                </InputGroup>
                            </FormGroup>

                        </Col>
                    </Row>

                    <Row>
                        <Col md={6}>
                            <FormGroup>
                                <Label className="fw-semi-bold">{t("Upload document needed, format PDF")}</Label>
                                <ul className="mb-2">
                                    {existingFiles.map((file, index) => (
                                        <li
                                            key={file.id}
                                            style={{
                                                display: "flex",
                                                justifyContent: "space-between",
                                                alignItems: "center",
                                            }}
                                        >
                                            <a href={config.baseURL + "/" + file.file} target="_blank" rel="noopener noreferrer">
                                                {file.file.split("/").pop()}
                                            </a>
                                            <button
                                                type="button"
                                                className="btn btn-link"
                                                onClick={() => handleRemoveFile(index, true)}
                                            >
                                                <i className="fa fa-times" />
                                            </button>
                                        </li>
                                    ))}
                                    {formik.values.files.map((file, index) => (
                                        <li
                                            key={`local-${index}`}
                                            style={{
                                                display: "flex",
                                                justifyContent: "space-between",
                                                alignItems: "center",
                                            }}
                                        >
                                            <span>{file.name}</span>
                                            <button
                                                type="button"
                                                className="btn btn-link"
                                                onClick={() => handleRemoveFile(index, false)}
                                            >
                                                <i className="fa fa-times" />
                                            </button>
                                        </li>
                                    ))}
                                </ul>
                                <Dropzone
                                    onDrop={handleDrop}
                                    accept={{ "application/pdf": [], "image/*": [] }}
                                    disabled={isDisabled}
                                >
                                    {({ getRootProps, getInputProps }) => (
                                        <div {...getRootProps()}>
                                            <input {...getInputProps()} />
                                            <Button color="primary" type="button">
                                                <i className="fa fa-upload me-2" />
                                                {t("Upload files")}
                                            </Button>
                                        </div>
                                    )}
                                </Dropzone>
                            </FormGroup>
                        </Col>
                    </Row>



                    {!dataLoading && (!submissions || submissions?.length === 0) && role === "ROLE_COMPANY" &&
                        <FormGroup className="mt-4">
                            <Button color={"danger"}
                                onClick={() => {
                                    history.push(`/app/${quoteId}/form`);
                                }}
                            >{t("More information")}</Button>
                        </FormGroup>
                    }

                    {role === "ROLE_ADMIN" &&
                        <Row>
                            <Col md={6}>
                                <FormGroup>
                                    <Label className="fw-semi-bold" for="statusId">{t("Status")}</Label>
                                    <AsyncSelectField
                                        url="/options-list/quote_status"
                                        defaultValue={formik.values.statusId}
                                        tsContext="quoteStatus"
                                        isDisabled={isDisabled}
                                        handleSelectChange={(selectedOption) =>
                                            formik.setFieldValue("statusId", selectedOption.id)
                                        }
                                    />
                                    {formik.touched.statusId && formik.errors.statusId && (
                                        <div className="text-danger mt-1">
                                            {t(formik.errors.statusId)}
                                        </div>
                                    )}
                                </FormGroup>
                            </Col>
                        </Row>}

                    {(quote?.esignBuyerCompletePdf || quote?.esignSellerCompletePdf) && (quote?.esignBuyerCompletePdf !== "null" && quote?.esignSellerCompletePdf !== "null") &&
                        <FormGroup>
                            <Label className="fw-semi-bold">{t("Esign pdfs")}</Label>
                            {quote?.esignBuyerCompletePdf && <div><a href={`${config.baseURL}/${quote.esignBuyerCompletePdf}`} target="_blank" rel="noopener noreferrer">{t("Esign buyer pdf")}</a></div>}
                            {quote?.esignSellerCompletePdf && <div><a href={`${config.baseURL}/${quote.esignSellerCompletePdf}`} target="_blank" rel="noopener noreferrer">{t("Esign seller pdf")}</a></div>}
                        </FormGroup>
                    }

                </form>

                {submissions && submissions.length > 0 &&
                    <div className="mt-5">
                        <FormSubmit ref={formSubmitRef} submission={submissions[0]} quoteId={quoteId} isDisabled={isDisabled} />
                    </div>
                }


                {quote && quote.statusId === config.quoteStatuses.approve && <Row>
                    <Col md={6}><EsignForm
                        buyerCompanyName={quote.buyerCompanyName}
                        buyerCompanyEmail={quote.buyerCompanyEmail}
                        sellerCompanyName={quote.sellerCompanyName}
                        sellerCompanyEmail={quote.sellerCompanyEmail}
                        sellerCompanyId={quote.sellerCompanyId}
                        buyerCompanyId={quote.buyerCompanyId}
                        quoteId={quoteId}
                        setLoading={setEsignLoading}
                        ref={esignRef}
                        esignStartDate={quote.esignStartDate}
                    />

                    </Col>
                </Row>}

                <Row>
                    <Col md={6}>
                        <FormGroup>
                            <Label className="fw-semi-bold" for="comment">{t("Comment")}</Label>
                            <Input
                                id="description"
                                name="comment"
                                type="textarea"
                                value={formik.values.comment}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                readOnly={isDisabled}
                                invalid={
                                    formik.touched.comment &&
                                    !!formik.errors.comment
                                }
                            />

                        </FormGroup>
                    </Col>
                </Row>

                <Row className="mt-4">

                    <>
                        {quote && !isDisabled && <Col md="12"><p className="text-danger">{t("Don't forget to Update if you have made any changes")}</p></Col>}
                        <Col md={12}>
                            {quote && !isDisabled &&
                                <Button type="button" color="primary" onClick={
                                    formik.handleSubmit
                                }>
                                    {isSubmitting ? <i className="fa fa-spin me-2 fa-spinner" /> : ""}
                                    {t("Update")}
                                </Button>}

                            {quote && quote.statusId === config.quoteStatuses.approve &&
                                <Button color="secondary" type="button" onClick={() => {
                                    esignRef.current.submit();
                                }}>
                                    {esignLoading ? <i className="fa fa-spin me-2 fa-spinner" /> : ""}
                                    {t('Skapa avtal för signering')}
                                </Button>}

                            {quote && quote?.statusId === config.quoteStatuses.approve && <PrintButton quoteId={quoteId} />}

                            {quote && quote.statusId !== config.quoteStatuses.approve && !isDisabled && role === "ROLE_COMPANY" && <>
                                <Button type="button" className="ms-3 me-3" color={formik.values.statusId === config.quoteStatuses.approve ? "danger" : "info"} onClick={() => formik.setFieldValue("statusId", config.quoteStatuses.approve)}>{t("Approve", {
                                    context: "quoteStatus"
                                })}</Button>
                                <Button type="button" className="me-3" color={formik.values.statusId === config.quoteStatuses.denied ? "danger" : "info"} onClick={() => formik.setFieldValue("statusId", config.quoteStatuses.denied)}>{t("Denied", {
                                    context: "quoteStatus"
                                })}</Button>
                                <Button type="button" className="me-3" color={formik.values.statusId === config.quoteStatuses.changes ? "danger" : "info"} onClick={() => formik.setFieldValue("statusId", config.quoteStatuses.changes)}>{t("Changes", {
                                    context: "quoteStatus"
                                })}</Button>
                            </>
                            }
                        </Col>
                    </>
                    {
                        (quote && quote?.statusId === config.quoteStatuses.orderCreated && currentUser?.userCompanyTypeId === 1) &&
                        <Col md={12}>
                            <Button onClick={completeQuote} type="button" color="primary" >
                                {isSubmitting ? <i className="fa fa-spin me-2 fa-spinner" /> : ""}
                                {`Uppdraget är slutförd (Slutförd)`}</Button>
                        </Col>
                    }

                    {quote && quote.statusId === config.quoteStatuses.complete && <Col md={12}>
                        <p className="fw-semi-bold">{t("Order completed")}: {moment(quote.dateUpdated).format("YYYY-MM-DD HH:mm")}</p>
                    </Col>}
                </Row>

            </Widget>
        </div>
    );
};

export default QuoteForm;
