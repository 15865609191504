import React, { useEffect, useState, useCallback } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import { Form, FormGroup, Label, Input, Button, Container, Col, Row } from "reactstrap";
import Dropzone from "react-dropzone";
import Widget from "../../components/Widget";
import axios from "axios";
import { useParams } from "react-router-dom";
import AsyncSelectField from "../../components/async-select";
import { useTranslation } from "react-i18next";
import e from "../forms/elements/Elements.module.scss";
import { push } from "connected-react-router";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import config from "../../config";
import IconUploader from "./components/upload-icon";

const validationSchema = yup.object({
  description: yup.string().required("Description is required"),
  email: yup.string().email("Invalid email").nullable(),
  phone: yup.string().nullable(),
  address: yup.string().nullable(),
  city: yup.string().nullable(),
  postNr: yup.string().nullable(),
  country: yup.string().nullable()
});

function mergeSocialMedia(defaultValues, response) {
  const responseMap = new Map(response.map(item => [item.name, item]));

  // Extract fixed social media values
  const socialMedia = defaultValues.map(defaultItem => {
    const responseItem = responseMap.get(defaultItem.name);
    return responseItem ? responseItem : defaultItem;
  });

  // Extract additional social media fields that are not in default values
  const anotherSocialMedia = response.filter(item =>
    !defaultValues.some(defaultItem => defaultItem.name === item.name)
  );

  return { socialMedia, anotherSocialMedia };
}


const CompanyForm = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { companyId } = useParams();
  const [company, setCompany] = useState(null);
  const initialValues = {
    description: "",
    categoryIds: [],
    logo: null,
    background: null,
    socialMedia: [
      { name: "Facebook", link: "", icon: "" },
      { name: "Snapchat", link: "", icon: "" },
      { name: "Instagram", link: "", icon: "" },
      { name: "LinkedIn", link: "", icon: "" },
      { name: "Youtube", link: "", icon: "" },
      { name: "Twitter", link: "", icon: "" },
      {
        name: "TikTok",
        link: "",
        icon: ""
      },
      {
        name: "Pinterest",
        link: "",
        icon: ""
      }
    ],
    anotherSocialMedia: [
    ]
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      const formData = new FormData();

      // Combine socialMedia and anotherSocialMedia
      const combinedSocialMedia = [...values.socialMedia] || [];
      (values.anotherSocialMedia || []).forEach((social) => {
        combinedSocialMedia.push(social);
      });

      // Handle logo and background
      if (values.logo) {
        formData.append("logo", values.logo);
      } else {
        formData.append("logo", "");
      }
      if (values.background) {
        formData.append("background", values.background);
      } else {
        formData.append("background", "");
      }

      // Append combined socialMedia
      combinedSocialMedia.forEach((social, index) => {
        formData.append(`socialMedia[${index}][name]`, social.name);
        formData.append(`socialMedia[${index}][link]`, social.link);
        formData.append(`socialMedia[${index}][icon]`, social.icon);
        formData.append(`socialIcons[${index}]`, social.icon);
      });

      // Handle other fields
      Object.keys(values).forEach((key) => {
        if (
          key !== "logo" &&
          key !== "background" &&
          key !== "socialMedia" &&
          key !== "anotherSocialMedia"
        ) {
          if (Array.isArray(values[key])) {
            values[key].forEach((item, index) =>
              formData.append(`${key}[${index}]`, item)
            );
          } else if (values[key] !== null && values[key] !== undefined) {
            formData.append(key, values[key]);
          }
        }
      });

      // Submit formData
      setLoading(true);
      axios.post("/companies/" + companyId, formData).then((response) => {
        toast.success("Company updated successfully");
      }).catch((error) => {
        toast.error("Error updating company");
      }).finally(() => {
        setLoading(false);
      });
    },
  });

  useEffect(() => {
    axios.get("/companies/" + companyId).then((response) => {
      setCompany(response.data);

    });

  }, [companyId]);

  useEffect(() => {
    if (company) {
      const { socialMedia, anotherSocialMedia } = mergeSocialMedia(initialValues.socialMedia, company.socialMedia || []);

      formik.setValues({
        ...company,
        socialMedia: socialMedia,
        anotherSocialMedia: anotherSocialMedia,
        categoryIds: company.categories.map((category) => category.id),
      });

    }
  }, [company]);


  const [previewLogo, setPreviewLogo] = React.useState(null);

  const [previewBackground, setPreviewBackground] = React.useState(null);

  const [loading, setLoading] = useState(false);

  const handleLogoDrop = (acceptedFiles, setFieldValue, type) => {
    const file = acceptedFiles[0];
    if (file) {

      setFieldValue(type, file);
      if (type === "background") {
        setPreviewBackground(URL.createObjectURL(file));
      } else if (type === "logo") {
        setPreviewLogo(URL.createObjectURL(file));
      }

    }
  };

  useEffect(() => {
    if (companyId && company?.logo) {
      setPreviewLogo(config.baseURL + "/" + company.logo);
    }

    if (companyId && company?.background) {
      setPreviewBackground(config.baseURL + "/" + company.background);
    }
  }, [companyId, company]);

  const addSocialMedia = () => {
    formik.setFieldValue("anotherSocialMedia", [
      ...formik.values.anotherSocialMedia,
      { name: "", link: "", icon: "" },
    ]);
  };

  const handleChangeIcon = useCallback((icon, index, name) => {
    if (name) {
      formik.setFieldValue(`socialMedia[${index}].icon`, icon);
    } else {
      formik.setFieldValue(`anotherSocialMedia[${index}].icon`, icon);
    }
  }
    , [formik.values.socialMedia]);

  return (
    <Container>
      <Widget className="p-3">
        <h2 className="mb-5">{t("Fill more information")}</h2>

        <Form onSubmit={formik.handleSubmit}>
          <FormGroup>
            <Label for="description">{t("Description")}</Label>
            <Input
              id="description"
              name="description"
              type="textarea"
              value={formik.values.description}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              invalid={formik.touched.description && !!formik.errors.description}
            />
          </FormGroup>
          <Row>
            <Col md="6">
              <Row className="mb-3">
                <Col md={12}>
                  <Label>{t("Logo")}</Label>
                </Col>
                {!!previewLogo ? (
                  <Col md={6}>
                    <Row className="image-preview">
                      <Col xs={12} md={6} className="image-container text-center">
                        <div className="image-wrapper">
                          <img src={previewLogo} alt="Channel Logo" className="img-fluid" />
                        </div>
                        <div className="mt-2 d-flex justify-content-between">
                          <Button
                            size="xs"
                            color="danger"
                            onClick={() => {
                              setPreviewLogo(null);
                              formik.setFieldValue("logo", null);
                            }}
                          >
                            {t("Remove")}
                          </Button>
                          <Button
                            size="xs"
                            color="primary"
                            onClick={() => {
                              document.querySelector(".hidden-upload-input").click();
                            }}
                          >
                            {t("Upload")}
                          </Button>
                        </div>
                        {/* Hidden input for manual upload */}
                        <input
                          type="file"
                          accept="image/*"
                          className="hidden-upload-input"
                          style={{ display: "none" }}
                          onChange={(e) =>
                            handleLogoDrop(e.target.files, formik.setFieldValue, "logo")
                          }
                        />
                      </Col>
                    </Row>
                  </Col>
                ) : (
                  <Col md={6} className="d-flex align-items-center">
                    <Dropzone
                      className={`dropzone ${e.dropzone}`}
                      onDrop={(acceptedFiles) => handleLogoDrop(acceptedFiles, formik.setFieldValue, "logo")}
                      accept="image/*"
                      multiple={false}
                    >
                      {({ getRootProps, getInputProps }) => (
                        <section>
                          <div {...getRootProps({ className: `dropzone align-items-center d-flex ${e.dropzone}` })}>
                            <input {...getInputProps()} />
                            <p>{t("Drag 'n' drop some files here, or click to select files")}</p>
                          </div>
                        </section>
                      )}
                    </Dropzone>
                  </Col>
                )}
              </Row>
            </Col>

            <Col md="6">
              <Row className="mb-3">
                <Col md={12}>
                  <Label>{t("Background")}</Label>
                </Col>
                {!!previewBackground ? (
                  <Col md={6}>
                    <Row className="image-preview">
                      <Col xs={12} md={6} className="image-container text-center">
                        <div className="image-wrapper">
                          <img src={previewBackground} alt="Background" className="img-fluid" />
                        </div>
                        <div className="mt-2 d-flex justify-content-between">
                          <Button
                            size="xs"
                            color="danger"
                            onClick={() => {
                              setPreviewBackground(null);
                              formik.setFieldValue("background", null);
                            }}
                          >
                            {t("Remove")}
                          </Button>
                          <Button
                            size="xs"
                            color="primary"
                            onClick={() => {
                              document.querySelector(".hidden-upload-input").click();
                            }}
                          >
                            {t("Upload")}
                          </Button>
                        </div>
                        {/* Hidden input for manual upload */}
                        <input
                          type="file"
                          accept="image/*"
                          className="hidden-upload-input"
                          style={{ display: "none" }}
                          onChange={(e) =>
                            handleLogoDrop(e.target.files, formik.setFieldValue, "background")
                          }
                        />
                      </Col>
                    </Row>
                  </Col>
                ) : (
                  <Col md={6} className="d-flex align-items-center">
                    <Dropzone
                      className={`dropzone ${e.dropzone}`}
                      onDrop={(acceptedFiles) => handleLogoDrop(acceptedFiles, formik.setFieldValue, "background")}
                      accept="image/*"
                      multiple={false}
                    >
                      {({ getRootProps, getInputProps }) => (
                        <section>
                          <div {...getRootProps({ className: `dropzone align-items-center d-flex ${e.dropzone}` })}>
                            <input {...getInputProps()} />
                            <p>{t("Drag 'n' drop some files here, or click to select files")}</p>
                          </div>
                        </section>
                      )}
                    </Dropzone>
                  </Col>
                )}
              </Row>
            </Col>
          </Row>
          <Row>
            <Col md="6"><FormGroup>
              <Label for="categoryIds">{t("Category")}</Label>
              <AsyncSelectField
                isMulti
                defaultValue={formik.values.categoryIds}
                name="categoryIds"
                label="Category" url="/company-categories"

                handleSelectChange={(value) => {
                  formik.setFieldValue("categoryIds", value.map((item) => item.id));
                }}
              />
            </FormGroup></Col>
          </Row>

          <h4 className="mt-4">{t("Social media")}</h4>
          <hr />
          <Row >
            {formik.values.socialMedia.map((social, index) => (
              <Col key={index} md="6">

                <FormGroup>
                  <Label>{social.name}</Label>
                  <div className="d-flex">
                    <Input
                      placeholder={t("Enter a name")}
                      value={social.link}
                      onChange={(e) =>
                        formik.setFieldValue(`socialMedia[${index}].link`, e.target.value)
                      }
                    />
                    <div className="ms-2">
                      <IconUploader iconUrl={social.icon} name={social.name} index={index} onUpload={handleChangeIcon} />
                    </div>
                  </div>

                </FormGroup>


              </Col>
            ))}
          </Row>
          <hr />
          <h6 className="fw-semi-bold">{t("Another social media")}</h6>
          {formik.values.anotherSocialMedia.map((social, index) => (
            <Row key={index} className="mb-2">
              <Col md="6">
                <FormGroup>
                  <div className="d-flex">
                    <Input
                      placeholder={t("Enter a name")}
                      value={social.name}
                      onChange={(e) =>
                        formik.setFieldValue(`anotherSocialMedia[${index}].name`, e.target.value)
                      }
                    />
                    <div className="ms-2">
                      <IconUploader iconUrl={social.icon} index={index} onUpload={handleChangeIcon} />
                    </div>
                  </div>
                </FormGroup>
              </Col>
              <Col md="6">
                <FormGroup>
                  <Input
                    placeholder="Social Media Link"
                    value={social.link}
                    onChange={(e) =>
                      formik.setFieldValue(`anotherSocialMedia[${index}].link`, e.target.value)
                    }
                  />
                </FormGroup>
              </Col>
            </Row>
          ))}
          <div className="d-flex align-items-end justify-content-end">
            <Button type="button" color="primary" onClick={addSocialMedia}>
              {t("Add more")}
            </Button>
          </div>



          <div className="d-flex align-items-center mt-3 mb-3 justify-content-center">
            <Button type="submit" disabled={loading} color="primary" style={{
              marginRight: "20px"
            }}

              className="mt-3 mr-3">
              {loading ? <i className='fa fa-spinner fa-spin me-2'></i> : ""}
              {t("Save data")}
            </Button>
            <Button type="button" color="secondary" className="mt-3 me-3" disabled={loading}
              onClick={() => {
                dispatch(push("/app"));
              }}
            >
              {t("Do this later")}
            </Button>
          </div>


        </Form>
      </Widget>
    </Container>
  );
};

export default CompanyForm;
