import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import {
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Form,
    FormGroup,
    Label,
    Input,
} from "reactstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { toast } from "react-toastify";

const FeedbackModal = () => {
    const { t } = useTranslation();
    const [modal, setModal] = useState(false);

    const toggle = () => setModal(!modal);

    const formik = useFormik({
        initialValues: {
            name: "",
            email: "",
            mobileNr: "",
            feedbackMessage: "",
        },
        validationSchema: Yup.object({
            name: Yup.string().required(t("This field is required")).nullable(),
            email: Yup.string().email(t("Invalid email")).required(t("This field is required")).nullable(),
            mobileNr: Yup.string().matches(/^\d+$/, t("Invalid phone number")).required(t("This field is required")).nullable(),
            feedbackMessage: Yup.string().required(t("This field is required")).nullable(),
        }),
        onSubmit: async (values, { setSubmitting, resetForm }) => {
            try {
                await axios.post("/other/admin-feedback-mail", values);
                toast.success(t("Feedback sent successfully"));
                toggle();
                resetForm();
            } catch (error) {
                console.error(t("Error sending feedback"), error);
            } finally {
                setSubmitting(false);
            }
        },
    });

    return (
        <>
            <Button className="btn-feedback" onClick={toggle}>
                {t("Send feedback")}
            </Button>

            <Modal isOpen={modal} toggle={toggle}>
                <ModalHeader toggle={toggle}>{t("Send feedback")}</ModalHeader>
                <Form onSubmit={formik.handleSubmit}>
                    <ModalBody className="bg-white">
                        <FormGroup>
                            <Label for="name">{t("Name")}</Label>
                            <Input type="text" name="name" id="name" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.name} />
                            {formik.touched.name && formik.errors.name ? <div className="text-danger">{formik.errors.name}</div> : null}
                        </FormGroup>
                        <FormGroup>
                            <Label for="email">{t("Email")}</Label>
                            <Input type="email" name="email" id="email" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.email} />
                            {formik.touched.email && formik.errors.email ? <div className="text-danger">{formik.errors.email}</div> : null}
                        </FormGroup>
                        <FormGroup>
                            <Label for="mobileNr">{t("Phone number")}</Label>
                            <Input type="text" name="mobileNr" id="mobileNr" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.mobileNr} />
                            {formik.touched.mobileNr && formik.errors.mobileNr ? <div className="text-danger">{formik.errors.mobileNr}</div> : null}
                        </FormGroup>
                        <FormGroup>
                            <Label for="feedbackMessage">{t("Feedback")}</Label>
                            <Input type="textarea" name="feedbackMessage" id="feedbackMessage" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.feedbackMessage} />
                            {formik.touched.feedbackMessage && formik.errors.feedbackMessage ? <div className="text-danger">{formik.errors.feedbackMessage}</div> : null}
                        </FormGroup>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" type="submit" disabled={formik.isSubmitting}>
                            {formik.isSubmitting && <i className="fa fa-spinner fa-spin me-2" />}
                            {t("Send")}
                        </Button>
                        <Button color="secondary" onClick={toggle}>
                            {t("Cancel")}
                        </Button>
                    </ModalFooter>
                </Form>
            </Modal>
        </>
    );
};

export default FeedbackModal;
