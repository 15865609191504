import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import s from './Sidebar.module.scss';
import LinksGroup from './LinksGroup/LinksGroup';
import { openSidebar, closeSidebar, changeActiveSidebarItem } from '../../actions/navigation';
import isScreen from '../../core/screenHelper';
import User from '../../images/sidebar/basil/User';
import Envelope from '../../images/sidebar/basil/Envelope';
import ShoppingCart from '../../images/sidebar/basil/ShoppingCart';
import Stack from '../../images/sidebar/basil/Stack';
import FeedbackModal from '../../pages/feedback';

import Logo from '../../images/logo-sidebar.png';
import { useTranslation } from 'react-i18next';

const Sidebar = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation();

  const {
    sidebarStatic,
    sidebarOpened,
    activeItem,
  } = useSelector((state) => ({
    sidebarStatic: state.navigation.sidebarStatic,
    sidebarOpened: state.navigation.sidebarOpened,
    activeItem: state.navigation.activeItem,
  }));

  const onMouseEnter = useCallback(() => {
    if (!sidebarStatic && (isScreen('lg') || isScreen('xl'))) {
      const paths = location.pathname.split('/');
      paths.pop();
      dispatch(openSidebar());
      dispatch(changeActiveSidebarItem(paths.join('/')));
    }
  }, [sidebarStatic, location.pathname, dispatch]);

  const onMouseLeave = useCallback(() => {
    if (!sidebarStatic && (isScreen('lg') || isScreen('xl'))) {
      dispatch(closeSidebar());
      dispatch(changeActiveSidebarItem(null));
    }
  }, [sidebarStatic, dispatch]);

  return (
    <div className={`${(!sidebarOpened && !sidebarStatic) ? s.sidebarClose : ''} ${s.sidebarWrapper}`}>
      <nav
        onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}
        className={s.root}
      >
        <header className={s.logo}>
          <a href="">
            <img src={Logo} alt="logo" />
          </a>
        </header>
        <ul className={s.nav}>
          <LinksGroup
            onActiveSidebarItemChange={(activeItem) => dispatch(changeActiveSidebarItem(activeItem))}
            activeItem={activeItem}
            header={t("Companies")}
            isHeader
            labelColor="danger"
            iconElement={<User />}
            conName="flaticon-user"
            link="/admin/companies"
            index="companies"
            label=""
            exact={false}
            childrenLinks={[
              {
                header: t('All companies'), link: '/admin/companies',
              },
              {
                header: t('Company categories'), link: '/admin/company-categories'
              }
            ]}
          />
          <LinksGroup
            onActiveSidebarItemChange={(activeItem) => dispatch(changeActiveSidebarItem(activeItem))}
            activeItem={activeItem}
            header={t("User management")}
            isHeader
            labelColor="danger"
            iconElement={<User />}
            conName="flaticon-user"
            link="/admin/users"
            index="users"
            exact={false}
            childrenLinks={[
              {
                header: t('Users'), link: '/admin/users',
              },
              {
                header: t('Create new'), link: '/admin/users/new',
              }
            ]}
          />

          <LinksGroup
            onActiveSidebarItemChange={(activeItem) => dispatch(changeActiveSidebarItem(activeItem))}
            activeItem={activeItem}
            header={t("Email templates")}
            link="/admin/email-templates"
            iconElement={<Envelope />}
            isHeader
            iconName="flaticon-paper-plane"
            index="email-templates"
          />

          <LinksGroup
            onActiveSidebarItemChange={(activeItem) => dispatch(changeActiveSidebarItem(activeItem))}
            activeItem={activeItem}
            header={t("Quotes")}
            link="/admin/quotes"
            iconElement={<Stack />}
            isHeader
            iconName="flaticon-paper-plane"
            index="quotes"
          />

          <LinksGroup
            onActiveSidebarItemChange={(activeItem) => dispatch(changeActiveSidebarItem(activeItem))}
            activeItem={activeItem}
            header={t("Orders")}
            link="/admin/orders"
            iconElement={<ShoppingCart />}
            isHeader
            iconName="flaticon-paper-plane"
            index="orders"
          />
        </ul>

        <div className={s.sidebarFooter}>
          <FeedbackModal />
        </div>
      </nav>
    </div>
  );
};


export default Sidebar;
