import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';

import { NavbarTypes } from '../../reducers/layout';
import Logo from '../../images/logo.png';
import UserDefault from '../../images/sidebar/basil/UserDefault';
import PowerButton from '../../images/sidebar/basil/PowerButton';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import {
  Navbar,
  Nav,
  Dropdown,
  NavItem,
  NavLink,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledTooltip,
  InputGroup,
  Input,
  Form,
  FormGroup,
} from 'reactstrap';
import cx from 'classnames';
import Notifications from '../Notifications';
import { logoutUser } from '../../actions/auth';
// import Joyride, { STATUS } from 'react-joyride';
import { toggleSidebar, openSidebar, closeSidebar, changeActiveSidebarItem } from '../../actions/navigation';

import adminDefault from '../../images/chat/chat2.png';
import Menu from '../../images/sidebar/basil/Menu';
import Exchange from '../../images/sidebar/basil/Exchange';
import Cross from '../../images/sidebar/basil/Cross';
import Settings from '../../images/sidebar/basil/Settings';
import Search from '../../images/sidebar/basil/Search';
import EnvelopeBlack from '../../images/sidebar/basil/EnvelopeBlack';
import CalendarIcon from '../../images/sidebar/Outline/Calendar';


import s from './Header.module.scss';
import config from '../../config';

import adamPatterson from '../../images/banners/adam-patterson.jpg';
import gustavoZambelli from '../../images/banners/gustavo-zambelli.jpg';
import jasonLeung from '../../images/banners/jason-leung.jpg';
import mariaOrlova from '../../images/banners/maria-orlova.jpg';
import samQuek from '../../images/banners/sam-quek.jpg';
import seanPollock from '../../images/banners/sean-pollock.jpg';
import shengHu from '../../images/banners/sheng-hu.jpg';
import taroOhtani from '../../images/banners/taro-ohtani.jpg';
import timMossholder from '../../images/banners/tim-mossholder.jpg';


const Header = () => {
  const dispatch = useDispatch();
  const location = useLocation();

  const {
    sidebarStatic,
    openUsersList,
    currentUser,
    sidebarOpened,


  } = useSelector((store) => ({
    sidebarStatic: store.navigation.sidebarStatic,
    sidebarOpened: store.navigation.sidebarOpened,
    openUsersList: store.chat.openUsersList,
    currentUser: store.auth.currentUser,
  }));

  const [menuOpen, setMenuOpen] = useState(false);
  const [notificationsOpen, setNotificationsOpen] = useState(false);


  const avatar = window.localStorage.getItem('userCompanyLogo') ? config.baseURL + "/" + window.localStorage.getItem('userCompanyLogo') : null;
  const firstUserLetter = currentUser && (currentUser.firstname || currentUser.email)?.[0]?.toUpperCase();


  const toggleNotifications = () => {
    setNotificationsOpen(!notificationsOpen);
  };

  const doLogout = () => {
    dispatch(logoutUser());
  };

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const toggleSidebar = () => {
    dispatch(toggleSidebar());
    // if (this.props.sidebarStatic) {
    //   localStorage.setItem('staticSidebar', 'false');
    //   this.props.dispatch(changeActiveSidebarItem(null));
    // } else {
    //   localStorage.setItem('staticSidebar', 'true');
    //   const paths = this.props.location.pathname.split('/');
    //   paths.pop();
    //   this.props.dispatch(changeActiveSidebarItem(paths.join('/')));
    // }

    if (sidebarStatic) {
      localStorage.setItem('staticSidebar', 'false');
      dispatch(changeActiveSidebarItem(null));
    } else {
      localStorage.setItem('staticSidebar', 'true');
      const paths = location.pathname.split('/');
      paths.pop();
      dispatch(changeActiveSidebarItem(paths.join('/')));
    }

  };


  // switchSidebar() {
  //   if (this.props.sidebarOpened) {
  //     this.props.dispatch(closeSidebar());
  //     this.props.dispatch(changeActiveSidebarItem(null));
  //   } else {
  //     const paths = this.props.location.pathname.split('/');
  //     paths.pop();
  //     this.props.dispatch(openSidebar());
  //     this.props.dispatch(changeActiveSidebarItem(paths.join('/')));
  //   }
  // }

  const switchSidebar = () => {
    if (sidebarOpened) {
      dispatch(closeSidebar());
      dispatch(changeActiveSidebarItem(null));
    } else {
      const paths = location.pathname.split('/');
      paths.pop();
      dispatch(openSidebar());
      dispatch(changeActiveSidebarItem(paths.join('/')));
    }
  };



  const navbarType = localStorage.getItem('navbarType') || 'static';

  const { t } = useTranslation();

  const banners = [
    adamPatterson,
    gustavoZambelli,
    jasonLeung,
    mariaOrlova,
    samQuek,
    seanPollock,
    shengHu,
    taroOhtani,
    timMossholder
  ];

  return (
    <>
      <Navbar
        className={`${s.root} d-print-none ${navbarType === NavbarTypes.FLOATING ? s.navbarFloatingType : ''}`}
        style={{ zIndex: !openUsersList ? 100 : 0 }}
      >
        <div className="d-flex flex-row justify-content-md-start flex-grow-1 align-content-center align-self-start">
          {/* <div className={`${s.navbarBrand} d-md-none`}>
            <header>
              <Link to="">
                <img className="logo" src={Logo} alt="logo" />
              </Link>
            </header>
          </div>
        </div>

        <div> */}
          <Nav className="my-auto">
            <NavItem>
           
             
              <NavLink className="fs-lg d-md-none" onClick={switchSidebar}>
                <span
                  className={`rounded rounded-lg d-md-none d-sm-down-block`}>
                  <span
                    className={s.headerSvgFlipColor}
                    style={{ fontSize: 30 }}
                  >
                    <Menu />
                  </span>
                </span>
                
              </NavLink>
            </NavItem>
          </Nav>


          <NavLink className={`${s.navbarBrand} d-md-none`}>
            <header>
              <Link to="">
                <img className="logo" src={Logo} alt="logo" />
              </Link>
            </header>
          </NavLink>
        </div>

        <div>
          <Nav className="ms-auto">
            <Dropdown
              nav
              isOpen={notificationsOpen}
              toggle={toggleNotifications}
              id="basic-nav-dropdown"
              className={`${s.notificationsMenu}`}
            >
              <DropdownToggle nav caret className={s.headerSvgFlipColor}>
                <span className={`${s.avatar} rounded-circle float-start me-3`}>
                  {avatar ? (
                    <img
                      src={avatar}
                      onError={(e) => (e.target.src = adminDefault)}
                      alt="..."
                      title={currentUser?.firstname || currentUser?.email}
                    />
                  ) : (
                    <span title={currentUser?.firstname || currentUser?.email}>{firstUserLetter}</span>
                  )}
                </span>
                <span
                  className={`small m-2 d-sm-down-none ${s.headerTitle} ${sidebarStatic ? s.adminEmail : ''}`}
                >
                  {currentUser ? currentUser.email : ''}
                </span>
              </DropdownToggle>
            </Dropdown>

            <Dropdown
              nav
              isOpen={menuOpen}
              toggle={toggleMenu}
              className="tutorial-dropdown pr-4"
            >
              <DropdownToggle nav className={`${s.mobileCog}`}>
                <span className={`${s.headerSvgFlipColor}`}>
                  <Settings />
                </span>
              </DropdownToggle>
              <DropdownMenu end className={`${s.headerDropdownLinks} super-colors`}>
                {currentUser && <DropdownItem href={currentUser.role === "ROLE_COMPANY" ? "/app/edit_profile" : "/admin/edit_profile"}>
                  <span className={s.headerDropdownIcon}><UserDefault /></span>{t("My Account")}
                </DropdownItem>}

                <DropdownItem divider />
                <DropdownItem onClick={doLogout}>
                  <span className={s.headerDropdownIcon}><PowerButton /></span>{t("Log Out")}
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>
          </Nav>
        </div>
      </Navbar>
      {currentUser && currentUser.role === 'ROLE_COMPANY' && <div
        className={`${s.banner}`}
        style={{
          backgroundImage: `url('${banners[Math.floor(Math.random() * banners.length)]}')`,
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center center',
          backgroundSize: 'cover',
        }}
      ></div>}

    </>
  );
};
export default Header;
