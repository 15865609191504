import React, { useState, useEffect, memo } from 'react';
import {
    Button,
    Modal,
    ModalBody,
    Spinner,
    Row,
    Col,
    Input,

} from 'reactstrap';
import axios from 'axios';
import s from "../../extra/search/Search.module.scss";
import config from '../../../config';
import { useTranslation } from 'react-i18next';
import facebook from "../../../images/social-icons/facebook.png";
import instagram from "../../../images/social-icons/instagram.png";
import linkedin from "../../../images/social-icons/linkedin.png";
import twitter from "../../../images/social-icons/twitter.png";
import youtube from "../../../images/social-icons/youtube.png";
import snapchat from "../../../images/social-icons/snapchat.png";
import styled from "styled-components";
import {ToggleText} from "../../companies/list";

const MultiSelectCompaniesModal = memo(({ type, onSelectionChange }) => {
    const [modalOpen, setModalOpen] = useState(false);
    const [companies, setCompanies] = useState([]);
    const [selectedCompanies, setSelectedCompanies] = useState([]);
    const [loading, setLoading] = useState(false);
    const [searchTerm, setSearchTerm] = useState("");
    const [debouncedSearchTerm, setDebouncedSearchTerm] = useState("");

    const { t } = useTranslation();
    const toggleModal = () => setModalOpen(!modalOpen);

    useEffect(() => {
        if (modalOpen) {
            fetchCompanies();
        }
    }, [modalOpen]);

    useEffect(() => {
        // Debounce API call
        const handler = setTimeout(() => {
            setDebouncedSearchTerm(searchTerm);
        }, 300); // Adjust the delay as needed

        return () => {
            clearTimeout(handler);
        };
    }, [searchTerm]);

    useEffect(() => {
        if (debouncedSearchTerm !== "" || modalOpen) {
            fetchCompanies(debouncedSearchTerm);
        }
    }, [debouncedSearchTerm]);

    const fetchCompanies = async (query = "") => {
        setLoading(true);
        setSelectedCompanies([]);
        try {
            const response = await axios.get(`/companies?typeId=${type}&q=${query}`);
            setCompanies(response.data);
        } catch (error) {
            console.error('Error fetching companies:', error);
        } finally {
            setLoading(false);
        }
    };

    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
    };

    const handleSelect = (company) => {
        // company is the object, selectedCompanies is the array of objects. compare by company.id
        // check if company is already in the selectedCompanies array of objects.  If it is, remove it, if it is not, add it.
        // selectedCompanies is the array of objects, company is the object

        if (selectedCompanies.find((c) => c.id === company.id)) {
            setSelectedCompanies(selectedCompanies.filter((c) => c.id !== company.id));
        } else {
            setSelectedCompanies([...selectedCompanies, company]);
        }


    };

    const handleAdd = () => {
        if (onSelectionChange) {
            onSelectionChange(selectedCompanies);
        }
        toggleModal();
    }

    const defaultIcon = (name) => {
        switch (name) {
            case "Facebook":
                return facebook;
            case "Instagram":
                return instagram;
            case "LinkedIn":
                return linkedin;
            case "Twitter":
                return twitter;
            case "Youtube":
                return youtube;
            case "Snapchat":
                return snapchat;
            default:
                return "";
        }
    };

    const SocialStyle = styled.div`
        bottom: 0;
        `;
    
    const CompanyInfoStyle = styled.div`
        overflow: hidden;
        p {
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 4;
            -webkit-box-orient: vertical;

        }
        `;

    return (
        <>
            <Button color="primary" onClick={toggleModal}>
                <i className="fa fa-plus me-2"></i>
                {t("Add companies")}
            </Button>

            <Modal size="lg" isOpen={modalOpen} toggle={toggleModal}>

                <ModalBody className="bg-white">


                    <div className={s.root}>
                        <div className="mt-5 mb-4">
                            <Input
                                type="text"
                                placeholder={t("Search companies...")}
                                value={searchTerm}
                                onChange={handleSearchChange}
                            />
                        </div>
                        {loading ? (
                            <div className="text-center">
                                <Spinner />
                                <p>{t("Loading companies...")}</p>
                            </div>) : companies.length > 0 ? (
                                <>

                                    <div className="mb-2 d-flex justify-content-end">


                                        <Button type="button" color="success"
                                            onClick={handleAdd}
                                            disabled={selectedCompanies.length === 0}

                                        >{t("Add companies")}</Button>
                                    </div>

                                    <hr />

                                    {companies.map((company, index) => (
                                        <Row
                                            key={index}
                                            className={`mb-5 d-flex position-relative`}

                                        >
                                            <Col md={3}>
                                                <img
                                                    className="img-fluid"
                                                    src={
                                                        company.logo
                                                            ? config.baseURL + "/" + company.logo
                                                            : "https://placehold.co/400"
                                                    }
                                                    alt=""
                                                    onClick={() => handleSelect(company)}
                                                />
                                            </Col>
                                            <Col md={9}>
                                                <CompanyInfoStyle>
                                                    <Row>
                                                        <Col md={9}>
                                                            <h3 className={"mb-2 "}>
                                                                {company.name}
                                                            </h3>
                                                            <div className='mb-3'><ToggleText text={company.description || t("No description available")} /></div>
                                                            
                                                           
                                                        </Col>
                                                        <Col
                                                            md={3}
                                                            xs={12}
                                                            className="d-flex justify-content-center align-items-center"
                                                            onClick={() => handleSelect(company)}
                                                        >
                                                            {selectedCompanies.find(c => c.id === company.id) ? (
                                                                <i
                                                                    className="fa fa-3x fa-check-square"
                                                                    style={{ color: config.app.themeColors.primary }}
                                                                ></i>
                                                            ) : (
                                                                <i
                                                                    className="fa fa-3x fa-square-o"
                                                                    style={{ color: config.app.themeColors.primary }}
                                                                ></i>
                                                            )}
                                                        </Col>
                                                    </Row>

                                                    <SocialStyle className='position-absolute'> {
                                                        company.socialMedia.filter((media) => {
                                                            return media.link && (media.icon || defaultIcon(media.name)) && media.name;
                                                        }).map((socialMedia, index) => (
                                                            <a className="me-2" key={index} href={socialMedia.link} target="_blank" rel="noreferrer">
                                                                <img
                                                                    src={socialMedia?.icon &&  socialMedia?.icon !== "undefined" ? config.baseURL + "/" + socialMedia.icon : defaultIcon(socialMedia.name)}
                                                                    alt={socialMedia.name}
                                                                    style={{ width: 25, height: 25, marginRight: 5 }}
                                                                />
                                                            </a>
                                                        ))
                                                    }</SocialStyle>
                                                </CompanyInfoStyle>

                                            </Col>

                                        </Row>
                                    ))}

                                    <hr />

                                    <div className="mt-2 d-flex justify-content-end">

                                        <Button type="button" color="success"
                                            onClick={handleAdd}
                                            disabled={selectedCompanies.length === 0}

                                        >{t("Add companies")}</Button>
                                        <Button type="button" color="secondary" className="ms-2" onClick={toggleModal}>
                                            {t("Close")}
                                        </Button>
                                    </div>
                                </>

                            ) : (
                            <p>{t("No companies found.")}</p>
                        )
                        }

                    </div>

                </ModalBody>
            </Modal>
        </>
    );
});

export default MultiSelectCompaniesModal;
