import React, { useState, memo, useMemo } from "react";
import { useDropzone } from "react-dropzone";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import config from "../../../config";
import facebook from "../../../images/social-icons/facebook.png";
import instagram from "../../../images/social-icons/instagram.png";
import linkedin from "../../../images/social-icons/linkedin.png";
import twitter from "../../../images/social-icons/twitter.png";
import youtube from "../../../images/social-icons/youtube.png";
import snapchat from "../../../images/social-icons/snapchat.png";
import tiktok from "../../../images/social-icons/tiktok.png";
import threads from "../../../images/social-icons/threads.png";
import iconDefault from "../../../images/social-icons/default.png";


const Dropzone = styled.div`
    display: flex;
    align-items: center;
    .dropzone {
        border: 1px dashed #ccc;
        border-radius: 4px;
        height: 33px;
        width: 33px;
        cursor: pointer;
    }
    
    `;


const IconUploader = memo(({ iconUrl, onUpload, index, name }) => {
    const { t } = useTranslation();
    const [uploadedIcon, setUploadedIcon] = useState(null);

    const onDrop = (acceptedFiles) => {
        const file = acceptedFiles[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = () => {
                setUploadedIcon(reader.result);
                if (onUpload) onUpload(file, index, name);
            };
            reader.readAsDataURL(file);
        }
    };

    const { getRootProps, getInputProps } = useDropzone({
        onDrop,
        accept: "image/*",
        multiple: false,
    });

    const defaultIcon = (name) => {
        switch (name) {
            case "Facebook":
                return facebook;
            case "Instagram":
                return instagram;
            case "LinkedIn":
                return linkedin;
            case "Twitter":
                return twitter;
            case "Youtube":
                return youtube;
            case "Snapchat":
                return snapchat;
            case "TikTok":
                return tiktok;
            case "Threads":
                return threads;
            default:
                return iconDefault;
        }
    };

    return (
        <Dropzone>
            {(uploadedIcon || iconUrl || defaultIcon(name)) &&  <img
                src={uploadedIcon ? uploadedIcon : (iconUrl && iconUrl !== "undefined" ? `${config.baseURL}/${iconUrl}` : defaultIcon(name))}
                alt="Uploaded Icon"
                style={{
                    width: 33,
                    height: 33,
                    objectFit: "cover"
                }}
            />}
            {!defaultIcon(name) && <div
                {...getRootProps({
                    className: "dropzone d-flex align-items-center justify-content-center ms-2",
                })}
            >
                <input id="iconUpload" {...getInputProps()} />

                <i className="la la-upload" />

            </div>}
            


        </Dropzone>
    );
});

export default IconUploader;
